import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
require("jquery")
import jquery from 'jquery';
window.$ = window.jquery = jquery;

require("popper.js")
require("bootstrap")
require("jquery")
require("@nathanvda/cocoon")
require("chartkick/chart.js")
import '../app-assets/vendors/js/vendors.min.js'
import '../app-assets/vendors/js/charts/jquery.sparkline.min.js'
import '../app-assets/vendors/js/forms/select/select2.full.min.js'
import '../app-assets/vendors/js/forms/select/select2.min.js'
import '../app-assets/vendors/js/forms/validation/jqBootstrapValidation.js'
import '../app-assets/vendors/js/pickers/pickadate/picker.js'
import '../app-assets/vendors/js/pickers/pickadate/picker.date.js'
import '../app-assets/vendors/js/forms/toggle/switchery.min.js'
import '../app-assets/vendors/js/extensions/jquery.knob.min.js'
import '../app-assets/js/scripts/extensions/knob.js'
import '../app-assets/vendors/js/charts/raphael-min.js'
import '../app-assets/vendors/js/charts/jvector/jquery-jvectormap-2.0.3.min.js'
import '../app-assets/vendors/js/charts/jvector/jquery-jvectormap-world-mill.js'
import '../app-assets/data/jvector/visitor-data.js'
import '../app-assets/vendors/js/charts/chart.min.js'
import '../app-assets/vendors/js/extensions/unslider-min.js'
import '../app-assets/vendors/js/timeline/horizontal-timeline.js'
import '../app-assets/js/core/app-menu.js'
import '../app-assets/js/core/app.js'
import '../app-assets/js/scripts/pages/account-setting.js'
import '../app-assets/js/core/libraries/jquery_ui/jquery-ui.min.js'

import '../app-assets/vendors/js/forms/quill/quill.js'
import '../app-assets/vendors/js/extensions/dragula.min.js'

import '../app-assets/vendors/js/gallery/masonry/masonry.pkgd.min.js'
import '../app-assets/vendors/js/gallery/photo-swipe/photoswipe.min.js'
import '../app-assets/vendors/js/gallery/photo-swipe/photoswipe-ui-default.min.js'

import '../app-assets/js/scripts/pages/app-todo.js'
import '../app-assets/js/scripts/forms/select/form-select2.js'

import '../stylesheets/application.scss'